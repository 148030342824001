import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalState } from 'state';
import './select.scss';

const Select = ({ options, label, onChange, name, disabled }) => {
  const { t } = useTranslation();
  const [defaultValue] = useGlobalState(name);
  return (
    <div className='select-container'>
      <label className='select-container__label'>{label}</label>
      <select
        disabled={disabled}
        name={name}
        onChange={(e) => onChange(e)}
        className='select-container__element'
        value={defaultValue}
      >
        <option value=''>{t('select')}</option>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.label ?? ''}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
