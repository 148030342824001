import React from 'react';
import { useTranslation } from 'react-i18next';
import './dateInput.scss';

const DateInput = React.forwardRef(({ onChange, onBlur, name, label }, ref) => {
  const { t } = useTranslation();
  return (
    <div className='date-container'>
      <label className='input-label'>{t(label)}</label>
      <input
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        type='date'
        className='date-container__input primary-input'
      />
    </div>
  );
});

export default DateInput;
