import React from "react";
import "./footer.scss";
import logo from "assets/images/logo.jpg";
import { FaInstagram, FaTwitterSquare, FaFacebookSquare } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-container">
      <div className="footer-container__logo">
        <img className="footer-container__logo__pic" src={logo} />
      </div>
      <div className="footer-container__informations">
        <div className="footer-container__informations__upper">
          <div className="footer-container__informations__upper__open-hours">
            <a className="footer-container__informations__header">
              {t("officeOpenHours")}:
            </a>
            <a>{t("Mon-Fr")}: 8:30-16:30</a>
            <a>{t("weekendEmergencyNumber")}</a>
            <a>0048 664450845</a>
          </div>
          <div className="footer-container__informations__upper__emails">
            <a className="footer-container__informations__header">
              {t("recruitmentContact")}:
            </a>
            <a>Email:</a>
            <a>info@danzicare.com</a>
          </div>
        </div>
        <div className="footer-container__informations__bottom">
          <a className="footer-container__informations__header">Danzi Care</a>
          <a>{t("street")}.Mariana Seredyńskiego 1/5 80-753 Gdańsk</a>
          <div className="footer-container__informations__bottom__socials">
            <a
              href="https://www.facebook.com/danzige.pflege.9"
              className="footer-container__informations__header"
            >
              <FaFacebookSquare className="footer-container__informations__header__social-icon" />
              FACEBOOK
            </a>
            <a className="footer-container__informations__header">
              <FaInstagram className="footer-container__informations__header__social-icon" />
              INSTAGRAM
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
