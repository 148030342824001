import Footer from '../Footer';
import React from 'react';
import Header from '../Header';

import './pageWrapper.scss';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { setGlobalState } from 'state';

const PageWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (
      !(
        location.pathname.includes('offers') ||
        location.pathname.includes('/application-form/')
      )
    ) {
      setGlobalState('page', 0);
      setGlobalState('sort', '');
      setGlobalState('location', '');
      setGlobalState('codes', '');
      setGlobalState('locationsData', []);
      setGlobalState('offers', []);
    }
  }, [location]);
  return (
    <div className='page-wrapper'>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default PageWrapper;
