import { setGlobalState } from 'state';
import { isUkrainianOffer } from './constant';

export const getData = async (
  sort,
  page,
  { location, codes, id },
  callback,
  isMorePages
) => {
  try {
    fetch(
      `${process.env.REACT_APP_STRAPI}offers?_start=${page}&_limit=5&${
        (location ? 'location.miasto=' + location + '&' : '') +
        (codes ? 'location.kod_contains=' + codes + '&' : '') +
        (id
          ? `offer_id${isUkrainianOffer(id) ? '_contains' : ''}=` + id + '&'
          : '')
      }${sort ? sort : '_sort=id:DESC'}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (isMorePages && data.length < 5) {
          isMorePages(false);
        }
        return data.length ? callback([...data]) : callback([]);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getSpecialData = async (callback) => {
  try {
    fetch(`${process.env.REACT_APP_STRAPI}offers?czy_specjalna=true`)
      .then((response) => response.json())
      .then((data) => {
        return data.length && callback([...data]);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getMoreData = async (
  sort,
  page,
  { location, codes, id },
  callback,
  isMorePages,
  setIsLoading
) => {
  try {
    fetch(
      `${process.env.REACT_APP_STRAPI}offers?_start=${page}&_limit=5&${
        (location ? 'location.miasto=' + location + '&' : '') +
        (codes ? 'location.kod_contains=' + codes + '&' : '') +
        (id
          ? `offer_id${isUkrainianOffer(id) ? '_contains' : ''}=` + id + '&'
          : '')
      }${sort ? sort : '_sort=id:DESC'}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (isMorePages && data.length < 5) {
          isMorePages(false);
        }
        setIsLoading(false);
        if (data.length) {
          callback((prevData) => [...prevData, ...data]);
        }
      });
  } catch (error) {
    console.log(error);
  }
};

export const getLocations = async (callback) => {
  try {
    fetch(`${process.env.REACT_APP_STRAPI}locations`)
      .then((response) => response.json())
      .then((data) => {
        let resArr = [];
        data.filter(function (item) {
          let i = resArr.findIndex((x) => x.miasto == item.miasto);
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });
        const codes = [...new Set(data.map((location) => location.kod))];
        setGlobalState('locationsData', data);
        setGlobalState('codesData', codes);
        return callback(
          resArr.map((item) => ({
            ...item,
            value: item.miasto,
            label: item.miasto,
          }))
        );
      });
  } catch (error) {
    console.log(error);
  }
};

export const getCodes = (query, callback) => {
  try {
    fetch(
      `${process.env.REACT_APP_STRAPI}locations?${
        query ? 'kod_contains=' + query : ''
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        callback(
          Array.from(new Set(data?.map((o) => o.kod))).map((code) => ({
            value: code,
            label: code,
          }))
        );
      });
  } catch (error) {
    console.log(error);
  }
};
