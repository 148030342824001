import React from 'react';
import { useTranslation } from 'react-i18next';
import './loader.scss';

const Loader = ({ message }) => {
  const { t } = useTranslation();
  const isCompleted = message === 'sended';
  return (
    <div className='loading-container'>
      <div
        className={
          isCompleted
            ? 'lds-dual-ring lds-dual-ring--completed'
            : 'lds-dual-ring'
        }
      ></div>
      <span className='loading-container__message'>{t(message)}</span>
    </div>
  );
};

export default Loader;
