import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { generateDocument } from './generator';

import PrimaryInput from 'components/inputs/PrimaryInput';
import RadioInput from 'components/inputs/RadioInput';
import CheckboxInput from 'components/inputs/CheckboxInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import DateInput from 'components/inputs/DateInput';

import './applicationForm.scss';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';

const ApplicationForm = (props) => {
  const { t } = useTranslation();
  const [sending, setSending] = useState('');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (sending === 'sended') {
      setTimeout(() => setSending(''), 1500);
    }
  }, [sending]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setSending('sending');
    generateDocument(data, props?.location?.state?.props?.id, reset, setSending);
  };

  const isOtherChecked = watch('other');
  return (
    <div className='application-form-page'>
      {sending && <Loader message={sending} />}
      <h1 className='application-form-page__header'>{t('applicationForm')}</h1>
      <form style={{ display: 'contents' }} onSubmit={handleSubmit(onSubmit)}>
        <div className='application-form-page__basic-info'>
          <PrimaryInput label='fullName' {...register('fullName')} />
          <DateInput {...register('birthday')} label='birthday' />
          <PrimaryInput label='nationality' {...register('nationality')} />
          <PrimaryInput
            errors={errors?.contactInfo?.message}
            label='contactInfo'
            {...register('contactInfo', { required: 'fillThisField' })}
          />
          <PrimaryInput
            label='photo'
            type='file'
            accept='image/png, image/gif, image/jpeg'
            {...register('photo')}
          />
        </div>

        <div className='application-form-page__basic-info'>
          <PrimaryInput
            label='seniorityLabel'
            {...register('seniorityLabel')}
          />
          <PrimaryInput label='diseasesLabel' {...register('diseasesLabel')} />{' '}
          <DateInput {...register('startDate')} label='startDate' />
          <PrimaryInput label='dateOfStay' {...register('dateOfStay')} />
          <PrimaryInput label='hobby' {...register('hobby')} />
        </div>
        <div className='application-form-page__basic-info'>
          <label className='input-label application-form-page__multi-inputs-label'>
            {t('languageSkill')}
          </label>
          <RadioInput
            value={'veryGood'}
            label={t('veryGood')}
            {...register('languageSkills')}
            name='languageSkills'
          />
          <RadioInput
            value={'good'}
            label={t('good')}
            {...register('languageSkills')}
            name='languageSkills'
          />
          <RadioInput
            value={'communicative'}
            label={t('communicative')}
            {...register('languageSkills')}
            name='languageSkills'
          />
          <RadioInput
            value={'basic'}
            label={t('basic')}
            {...register('languageSkills')}
            name='languageSkills'
          />
          <label className='input-label application-form-page__multi-inputs-label'>
            {t('additionalQualifications')}
          </label>
          <CheckboxInput
            label='firstAidCourse'
            {...register('firstAidCourse')}
          />
          <CheckboxInput
            label='deLanguageCourse'
            {...register('deLanguageCourse')}
          />
          <CheckboxInput
            label='caregiverCourse'
            {...register('caregiverCourse')}
          />
          <CheckboxInput label='reference' {...register('reference')} />
          <CheckboxInput label='other' {...register('other')} />
          {isOtherChecked && <PrimaryInput {...register('otherText')} />}
        </div>

        <div className='application-form-page__basic-info'>
          <label className='input-label application-form-page__multi-inputs-label'>
            {t('smokeQuestion')}
          </label>
          <RadioInput
            value={true}
            label={t('yes')}
            name='smokeQuestion'
            {...register('smokeQuestion')}
          />
          <RadioInput
            value={false}
            label={t('no')}
            name='smokeQuestion'
            {...register('smokeQuestion')}
          />
          <label className='input-label application-form-page__multi-inputs-label'>
            {t('driverLicence')}
          </label>
          <RadioInput
            value={true}
            label={t('yes')}
            name='driverLicence'
            {...register('driverLicence')}
          />
          <RadioInput
            value={false}
            label={t('no')}
            name='driverLicence'
            {...register('driverLicence')}
          />
        </div>

        <div className='application-form-page__basic-info application-form-page__basic-info--moved-left'></div>
        <div className='application-form-page__basic-info application-form-page__basic-info--wide'>
          <div className='application-form-page__basic-info application-form-page__basic-info--margin-top'>
            <CheckboxInput
              errors={errors?.agreement?.message}
              label='agreement'
              {...register('agreement', { required: 'checkThisField' })}
            />
            <Link to='/rodo'>{t('permissions')}</Link>
          </div>
          <PrimaryButton
            className='application-form-page__button'
            color='green'
          >
            {t('send')}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default ApplicationForm;
