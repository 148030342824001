import React from 'react';
import { useTranslation } from 'react-i18next';

import 'styles/mixins.scss';
import './input.scss';

const PrimaryInput = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      name,
      children,
      label,
      type,
      accept,
      disabled,
      errors,
      autocomplete,
      onClick,
      className,
      classNameLabel,
      value,
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <div className='input-container'>
        <label className={`input-label ${classNameLabel}`}>{t(label)}</label>
        {errors && <span className='input-error'>{t(errors)}</span>}
        <input
          accept={accept}
          autoComplete={autocomplete === false ? 'off' : 'on'}
          type={type ? type : 'text'}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          ref={ref}
          value={value}
          className={`${
            errors ? 'primary-input primary-input--error' : 'primary-input'
          } ${className}`}
          placeholder={children}
          disabled={disabled}
        />
      </div>
    );
  }
);

export default PrimaryInput;
