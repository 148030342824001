import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import PrimaryButton from "components/buttons/PrimaryButton";
import PATH_URL from "routes/pathURL";

import "./offersItem.scss";

const OffersItem = (props) => {
  const {
    id,
    offer_id,
    tytul,
    location,
    data_rozpoczecia,
    stawka,
    oferta_dla,
    jezyk,
    specialOffer,
    prawo_jazdy,
    palenie,
    toCare,
    status,
    plec,
    kontent,
    data_zakonczenia,
    zdjecie,
    czy_pali,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="offer-container">
      <div className="offer-container__image-box">
        <img
          className="offer-container__image"
          src={`${process.env.REACT_APP_STRAPI_CLEAN}${zdjecie?.url}`}
        />
      </div>
      <div className="offer-container__content-box">
        <h2 className="offer-container__title">{tytul}</h2>
        <ul className="offer-container__details">
          <li className="offer-container__details-item">
            {t("location")}:{" "}
            <span className="offer-container__details-value">
              {location?.miasto} {location?.kod}
            </span>
          </li>
          <li className="offer-container__details-item">
            {t("date")}:{" "}
            <span className="offer-container__details-value">
              od{" "}
              {new Date(data_rozpoczecia).getTime() < new Date()
                ? "zaraz"
                : data_rozpoczecia}
              {data_zakonczenia &&
                data_rozpoczecia !== data_zakonczenia &&
                " do " + data_zakonczenia}
            </span>
          </li>
          <li className="offer-container__details-item">
            {t("salary")}:{" "}
            <span className="offer-container__details-value">
              {stawka}€ + PREMIA ŚWIĄTECZNA
            </span>
          </li>
          <li className="offer-container__details-item">
            {t("language")}:{" "}
            <span className="offer-container__details-value">{jezyk}</span>
          </li>
          <li className="offer-container__details-item">
            {t("candidateType")}:{" "}
            <span className="offer-container__details-value">
              {t(oferta_dla)}
            </span>
          </li>
          <li className="offer-container__details-item">
            {t("driverLicenceOffer")}:{" "}
            <span className="offer-container__details-value">
              {prawo_jazdy ? t("yes") : t("no")}
            </span>
          </li>
          <li className="offer-container__details-item">
            {t("smokeingOffer")}:{" "}
            <span className="offer-container__details-value">
              {czy_pali ? t("allowed") : t("smoking_filter")}
            </span>
          </li>
        </ul>
        <div className="offer-container__footer">
          <div style={{ width: "150px" }}>
            <PrimaryButton
              onClick={() =>
                history.push(`${PATH_URL.applicationForm}`, {props: {id}})
              }
              color="blue"
            >
              {t("applay")}
            </PrimaryButton>
          </div>
          <div style={{ width: "150px" }}>
            <PrimaryButton
              color="green"
              onClick={() =>
                history.push(PATH_URL.offers + "/" + id, { props })
              }
            >
              {t("readMore")}
            </PrimaryButton>
          </div>
        </div>
        <span className="offer-container__id" style={{}}>
          ID: {offer_id}
        </span>
      </div>
    </div>
  );
};

export default OffersItem;
