const PATH_URL = {
  root: '/',
  about: '/about',
  applicationForm: '/application-form',
  carrerGuide: '/carrer-guide',
  contact: '/contact',
  offers: '/offers',
  offerDetails: '/offers/:id',
  applicationFormDetails: '/application-form/:id',
  forFamily: '/for-family',
  rodo: '/rodo',
  rules: '/rules',
};

export default PATH_URL;
