import PrimaryButton from "components/buttons/PrimaryButton";
import PrimaryInput from "components/inputs/PrimaryInput";
import PrimaryTextArea from "components/TextAreas/PrimaryTextArea";
import React, { useRef } from "react";
import "./contactForm.scss";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CheckboxInput from "components/inputs/CheckboxInput";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [message, setMessage] = useState("");

  const isEmail = watch("email");
  const { t } = useTranslation();
  const form = useRef();
  const sendEmail = (e) => {
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_KEY,
        process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE,
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage("Wysłano");
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <form ref={form} onSubmit={handleSubmit((e) => sendEmail(e))}>
      <div className="contact-form">
        <h1 className="contact-form__title">{t("contactForm")}</h1>
        <div className="contact-form__personal">
          <PrimaryInput
            className="contact-form__input"
            {...register("fullName")}
          >
            {t("fullName")}
          </PrimaryInput>
        </div>

        <div className="contact-form__contact-data">
          <div className="contact-form__contact-data__item">
            <PrimaryInput
              className="contact-form__input"
              {...register("phoneNumber")}
            >
              {t("phoneNumber")}
            </PrimaryInput>
          </div>
          <div className="contact-form__contact-data__item">
            <PrimaryInput
              className="contact-form__input"
              {...register("email")}
            >
              Email
            </PrimaryInput>
          </div>
        </div>
        <div className="contact-form__message">
          <PrimaryTextArea {...register("message")}>
            {t("message")}
          </PrimaryTextArea>
        </div>
        <div className="contact-form__agreement">
          <CheckboxInput
            errors={errors?.agreement?.message}
            label="agreementContactForm"
            {...register("agreement", { required: "checkThisField" })}
          />
        </div>
        <Link to="/rodo">{t("permissions")}</Link>
        <div className="contact-form__button">
          <PrimaryButton disabled={!isEmail} color="blue">
            {t("send")}
          </PrimaryButton>
        </div>
        <a>{message}</a>
      </div>
    </form>
  );
};

export default ContactForm;
