import React from 'react';
import { useTranslation } from 'react-i18next';
import './radioInput.scss';

const RadioInput = React.forwardRef(
  ({ onChange, onBlur, name, label, value }, ref) => {
    const { t } = useTranslation();

    return (
      <div className='radio-container'>
        <input
          ref={ref}
          onChange={onChange}
          type='radio'
          value={value}
          name={name}
          className='radio-container__input'
        />
        <label className='radio-container__label'>{label}</label>
      </div>
    );
  }
);

export default RadioInput;
