import Home from 'pages/home';
import About from 'pages/about';
import ApplicationForm from 'pages/applicationForm';
import CarrerGuide from 'pages/carrerGuide';
import ForFamily from 'pages/forFamily';
import Contact from 'pages/contact';
import Offers from 'pages/offers';
import Offer from 'pages/offer';

import PATH_URL from './pathURL';
import Rodo from 'pages/Rodo';

const routes = [
  {
    id: 1,
    to: PATH_URL.root,
    exact: true,
    component: Home,
    name: 'home',
    isMenuItem: true,
  },
  {
    id: 6,
    to: PATH_URL.offers,
    exact: true,
    component: Offers,
    name: 'offers',
    isMenuItem: true,
  },
  {
    id: 2,
    to: PATH_URL.about,
    exact: true,
    component: About,
    name: 'about',
    isMenuItem: true,
  },
  {
    id: 3,
    to: PATH_URL.applicationForm,
    exact: true,
    component: ApplicationForm,
    name: 'applicationForm',
    isMenuItem: true,
  },
  {
    id: 4,
    to: PATH_URL.carrerGuide,
    exact: true,
    component: CarrerGuide,
    name: 'carrerGuide',
    isMenuItem: true,
  },
  {
    id: 5,
    to: PATH_URL.contact,
    exact: true,
    component: Contact,
    name: 'contact',
    isMenuItem: true,
  },
  {
    id: 7,
    to: PATH_URL.offerDetails,
    exact: true,
    component: Offer,
    name: 'offer',
    isMenuItem: false,
  },
  {
    id: 8,
    to: PATH_URL.applicationFormDetails,
    exact: true,
    component: ApplicationForm,
    name: 'applicationForm',
    isMenuItem: false,
  },
  {
    id: 9,
    to: PATH_URL.forFamily,
    exact: true,
    component: ForFamily,
    name: 'forFamily',
    isMenuItem: true,
  },
  {
    id: 10,
    to: PATH_URL.rodo,
    exact: true,
    component: Rodo,
    name: 'rodo',
    isMenuItem: false,
  },
];

export default routes;
