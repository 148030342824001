import 'styles/mixins.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrimaryTextArea = React.forwardRef(
  ({ onChange, onBlur, name, children, type, accept }, ref) => {
    const { t } = useTranslation();
    return (
      <div className='primary-text-area'>
        <textarea
          accept={accept}
          type={type ? type : 'text'}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          className='primary-text-area'
          placeholder={children}
        />
      </div>
    );
  }
);

export default PrimaryTextArea;
