import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import PrimaryButton from "components/buttons/PrimaryButton";
import ReactMarkdown from "react-markdown";

import PATH_URL from "routes/pathURL";

import "./offer.scss";

const Offer = (props) => {
  const {
    id,
    offer_id,
    tytul,
    location,
    data_rozpoczecia,
    data_zakonczenia,
    stawka,
    oferta_dla,
    opieka_nad,
    jezyk,
    specialOffer,
    prawo_jazdy,
    palenie,
    plec,
    kontent,
    zdjecie,
    czy_pali,
  } = props.location.state.props;
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="offer-page">
      <div className="offer-page__back-button-container">
        <PrimaryButton color="green" onClick={() => history.goBack()}>
          {`<`} {t("backToOffers")}
        </PrimaryButton>
      </div>
      <h2 className="offer-section__title">{tytul}</h2>
      <section className="offer-section">
        <span className="offer-section__id">ID: {offer_id}</span>
        <img
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "500px",
            maxWidth: "500px",
          }}
          src={`${process.env.REACT_APP_STRAPI_CLEAN}${zdjecie?.url}`}
        />
        <div>
          <ul className="offer-section__details">
            <li className="offer-section__details-item">
              {t("location")}:{" "}
              <span className="offer-container__details-value">
                {location?.miasto} {location?.kod}
              </span>
            </li>
            <li className="offer-section__details-item">
              {t("date")}:{" "}
              <span className="offer-container__details-value">
                od{" "}
                {new Date(data_rozpoczecia).getTime() < new Date()
                  ? "zaraz"
                  : data_rozpoczecia}
                {data_zakonczenia &&
                  data_rozpoczecia !== data_zakonczenia &&
                  " do " + data_zakonczenia}
              </span>
            </li>
            <li className="offer-section__details-item">
              {t("salary")}:{" "}
              <span className="offer-container__details-value">
                {stawka}€ + PREMIA ŚWIĄTECZNA
              </span>
            </li>
            <li className="offer-section__details-item">
              {t("language")}:{" "}
              <span className="offer-container__details-value">{jezyk}</span>
            </li>
            <li className="offer-section__details-item">
              {t("candidateType")}:{" "}
              <span className="offer-container__details-value">
                {t(oferta_dla)}
              </span>
            </li>{" "}
            <li className="offer-section__details-item">
              {t("toCare")}:{" "}
              <span className="offer-container__details-value">
                {t(opieka_nad)}
              </span>
            </li>{" "}
            <li className="offer-section__details-item">
              {t("driverLicenceOffer")}:{" "}
              <span className="offer-container__details-value">
                {prawo_jazdy ? t("yes") : t("no")}
              </span>
            </li>
            <li className="offer-section__details-item">
              {t("smokeingOffer")}:{" "}
              <span className="offer-container__details-value">
                {czy_pali ? t("allowed") : t("smoking_filter")}
              </span>
            </li>
          </ul>
          <div className="offer-section__description-box">
            <h4>{t("offerDescription")}:</h4>
            <div className="offer-section__description">
              {kontent && <ReactMarkdown>{kontent}</ReactMarkdown>}
            </div>
          </div>
        </div>
        <div className="offer-section__submit-button">
          <div style={{ width: "150px" }}>
            <PrimaryButton
              onClick={() => history.push(`${PATH_URL.applicationForm}`, {props: {id}})}
              color="green"
            >
              {t("applay")}
            </PrimaryButton>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Offer;
