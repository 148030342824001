import React from "react";
import DailyOffers from "./components/dailyOffers";
import Slider from "./components/slider";
import ContactForm from "components/forms/ContactForm";
import aplikacja from "./images/aplikacja.png";
import jezyki from "./images/jezyki.png";
import rozmowa from "./images/rozmowa.png";
import dokumenty from "./images/dokumenty.png";
import wspolpraca from "./images/wspolpraca.png";
import "./home.scss";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  const images = [
    "https://image.freepik.com/darmowe-zdjecie/starsi-ludzie-z-aprobatami_102671-1633.jpg",
    "https://image.freepik.com/darmowe-zdjecie/senior-kobieta-z-jej-opiekuna-na-zewnatrz_1098-2987.jpg",
    "https://image.freepik.com/darmowe-zdjecie/seniorzy-grajacy-w-gry_107420-36783.jpg",
    "https://image.freepik.com/darmowe-zdjecie/cieszac-sie-w-rodzinnym-czasie-starszym-mezczyzna-na-wozku-inwalidzkim-i-usmiechnieta-corka-w-ogrodzie_496169-737.jpg",
  ];
  return (
    <>
      <div className="home-page">
        <div className="home-page__left-column">
          <h1 className="home-page__title">{t("mainHeader")}</h1>
          <div className="home-page__image-slider">
            <Slider offers={images} type="img" />
          </div>
        </div>
        <div className="home-page__right-column">
          <DailyOffers />
          <div className="home-page__form">
            <ContactForm />
          </div>
        </div>
      </div>
      <div className="home-page-recruitment">
        <h2 className="home-page-recruitment__header">
          {t("recruitmentProcess")}
        </h2>
        <div className="home-page__job">
          <div className="home-page__job__wrapper">
            <h4 className="home-page__job__header">
              {t("sendingTheApplication")}
            </h4>
            <img src={aplikacja} className="home-page__job__pic" />
            <p className="home-page__job__paragraph">
              {t("sendingTheApplicationDescription")}
            </p>
          </div>
          <div className="home-page__job__wrapper">
            <h4 className="home-page__job__header">
              {t("recruitmentInterview")}
            </h4>
            <img src={rozmowa} className="home-page__job__pic" />
            <p className="home-page__job__paragraph">
              {t("recruitmentInterviewDescription")}
            </p>
          </div>
          <div className="home-page__job__wrapper">
            <h4 className="home-page__job__header">
              {t("verificationOfLanguageKnowledge")}
            </h4>
            <img src={jezyki} className="home-page__job__pic" />
            <p className="home-page__job__paragraph">
              {t("verificationOfLanguageKnowledgeDescription")}
            </p>
          </div>
          <div className="home-page__job__wrapper">
            <h4 className="home-page__job__header">
              {t("completingAndSigningDocuments")}
            </h4>
            <img src={dokumenty} className="home-page__job__pic" />
            <p className="home-page__job__paragraph">
              {t("completingAndSigningDocumentsDescription")}
            </p>
          </div>
          <div className="home-page__job__wrapper">
            <h4 className="home-page__job__header">
              {t("beginningOfCooperation")}
            </h4>
            <img src={wspolpraca} className="home-page__job__pic" />
            <p className="home-page__job__paragraph">
              {t("beginningOfCooperationDescription")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
