import React, { useEffect, useState } from "react";
import Slider from "../slider";
import { getSpecialData } from "api/service";

import "./dailyOffers.scss";
import { useTranslation } from "react-i18next";

const DailyOffers = () => {
  const [offers, setOffers] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    getSpecialData(setOffers);
  }, []);

  return (
    <div className="daily-offers-container">
      <h2 className="daily-offers-container__title">{t("day_offers")}</h2>
      <Slider offers={offers} type="object" />
    </div>
  );
};

export default DailyOffers;
