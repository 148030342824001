import React from "react";

import "./forFamily.scss";
const pic =
  "https://images.unsplash.com/flagged/photo-1577474810448-82eb81a48d13?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=872&q=80&fbclid=IwAR33v1bSeHgu7YovY_xPeR17uLJMd9OlnRJHRDbn0WTKe7ROitzUZMBLaxU";

const ForFamily = () => {
  return (
    <div className="for-family">
      <div className="for-family__page">
        <h2 className="for-family__page__section-header">
          Für Kunden Ausländischer Pflegekräfte aus Osteuropa für eine
          24-Stunden-Pflege!
        </h2>
         
        <p className="for-family__page__section-paragraph">
          Liebe Kundinnen und Kunden, Sie Suchen nach einer Alternative zum
          Pflegeheim? <br />
          Wir können Ihnen behilflich sein, eine bequeme Lösung zu finden, wie
          man Privatleben und Elternbetreuung in Einklang bringen kann.
          <br />
          Wie ein altes Sprichwort besagt: „Alte Bäume verpflanzt man nicht“
          <br />
          Anstatt einer unpersönlichen Pflegeeinrichtung können wir Ihnen eine 
          „Häusliche 24-Stunden-Pflege für Seniorinnen und Senioren“ anbieten.
          Insbesondere für Menschen die an Demenz erkrankt sind, ist es nicht
          immer leicht, sich an eine neue Umgebung anzupassen bzw.
          zurechtzufinden.  Wir sind überzeugt davon, dass die von uns
          angebotenen Dienstleistungen den Nerv der Zeit treffen und Ihnen
          helfen werden, diese Probleme gemeinsam in den eigenen vier Wänden zu
          lösen. Aus diesem Grund versuchen wir immer, die oder den richtigen
          Betreuer zu finden und dieser ein Teil Ihrer Familie wird.  Ihre
          Arbeit soll auf gegenseitigem Vertrauen und Zufriedenheit basieren,
          sodass unsere Fachkräfte immer wieder gerne bereit sind, zu Ihren
          Schützlingen zurückzukehren.
          <br />
          Wir möchten Ihnen mit unseren Dienstleistungen Ruhe und Sicherheit
          bieten.
          <br />
          Wer sind unsere Pflegekräfte?
          <br />
          In der Regel sind es Betreuerinnen oder Betreuer zwischen 25 und 70
          Jahren, die über Referenzen und Zertifikate verfügen die ihre
          Betreuungskompetenz bestätigen.
          <br />
          Die Arbeit in der Altenpflege ist für unsere Pflegekräfte eine
          Herzensangelegenheit und mehr als nur Arbeit oder Haushalt – es ist
          Liebe, Hingabe und Leidenschaft – für hilfsbedürftige Menschen.      
           
        </p>
           
        <h2 className="for-family__page__section-header">Pflegeleistungen:</h2> 
        <ul className="for-family__page__list">
          <li>Direkte häusliche Betreuung des Patienten</li>
          <li>Tagesablauf organisieren</li>
          <li>Körperpflege</li>
          <li>Hilfe beim Aufstehen, Anziehen und Gehen sichern</li>
          <li>Erinnern an die Medikamenteneinnahme</li>
          <li>Haushalt führen (Einkaufen, Kochen und Putzen)</li>
          <li>
            Gesellschaft leisten (Spazierengehen, Brett- oder Kartenspiele,
            Unterhaltung und Arztbesuche)
          </li>
          <li>Weitere Tätigkeiten in Absprache möglich</li> 
        </ul>
         
        <h2 className="for-family__page__section-header">
          Wenn Sie unsere Dienste nutzen möchten, können Sie sich auf Folgendes
          verlassen:  
        </h2>
        <ul className="for-family__page__list">
          <li>
            Effiziente Unterstützung bei der Auswahl des richtigen Betreuers
          </li>
          <li>Geringe Kosten und Vertretung bei Unwohlsein des Babysitters</li>
          <li>Große Auswahl an Bewerbern mit sehr guter Pflegeerfahrung</li>
          <li>Kurze Fristen für den Service</li>
          <li>Betreuungsumfang auf individuelle Bedürfnisse abgestimmt</li>
        </ul>
         
        <h2 className="for-family__page__section-header">
          Kosten für die Seniorenbetreuung:  
        </h2>
        <p className="for-family__page__section-paragraph">
          Die Kosten sind bei uns transparent und fair gestaltet. Es gibt keine
          zusätzlichen Gebühren und Nebenkosten. Die Kosten sind zweifelslos
          gering im Vergleich zu dem, was Ihre Familie durch eine ständige
          Betreuung Ihrer Liebsten leisten muss. Wir erstellen Ihnen gerne ein
          individuelles auf Ihre Bedürfnisse und Fähigkeiten zugeschnittenes
          Angebot oder alternativ eines unserer drei bereits vorgefertigten
          Angebote. Die Bewertung und Angebotserstellung ist für Sie kostenfrei.
          Rufen Sie uns an (0048 664450845) oder kontaktieren Sie uns per Mail
          (a.lewczuk@danzigepflege.eu) Wir freuen uns von Ihnen zu hören!  
          <br />
          <br />
          Rufen Sie uns an 0048 664450845, a.lewczuk@danzigepflege.eu
        </p>
      </div>
      <div className="for-family__picture">
        <img src={pic} className="for-family__picture__pic" />
      </div>
    </div>
  );
};

export default ForFamily;
