import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import routes from "routes/routes";
import { useTranslation } from "react-i18next";

import LanguageSelector from "components/LanguageSelector";

import "./mobileMenu.scss";

const MobileMenu = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  return (
    <ul className={classNames("mobile-list", { "mobile-list--open": isOpen })}>
      {routes
        .filter((r) => r.isMenuItem)
        .map((r) => (
          <li className="mobile-list__item" key={r.id}>
            <Link
              className="mobile-list__item__link"
              onClick={() => setIsOpen(false)}
              to={r.to}
            >
              {t(r.name)}
            </Link>
          </li>
        ))}
      <li className="mobile-list__item">
        <LanguageSelector />
      </li>
    </ul>
  );
};

export default MobileMenu;
