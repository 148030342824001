import classNames from 'classnames';
import React from 'react';
import 'styles/mixins.scss';
const PrimaryButton = ({ color, children, onClick, className, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type='submit'
      className={classNames(`primary-button ${className}`, {
        'primary-button--blue': color === 'blue',
        'primary-button--green': color === 'green',
      })}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
