import React from "react";
import "./rodo.scss";

const Rodo = () => {
  return (
    <div className="rodo">
      <h2 className="rodo__header"> Zgoda rekrutacyjna </h2>
      <p className="rodo__paragraph">
        Wyrażam zgodę na przetwarzanie moich danych osobowych przez Danzi Care
        sp. z o.o. z siedzibą w 80-753 Gdańsk, przy ulicy Mariana Seredyńskiego
        1/5, zarejestrowaną w Sądzie Rejonowym XXXXXX, pod numerem KRS:
        0000939485, posiadającą NIP: 5833442026 REGON: XXX (Administrator
        danych) w postaci imienia, nazwiska, numeru telefonu, adresu poczty
        elektronicznej, adresu oraz znajomości języka niemieckiego dla potrzeb
        niezbędnych do realizacji procesu przyszłych rekrutacji (zgodnie z
        ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych oraz zgodnie
        z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
        27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
        danych oraz uchylenia dyrektywy 95/46/WE (RODO), w tym przysłania mi
        ofert za pośrednictwem poczty elektronicznej, telefonu lub wiadomości
        sms. Obowiązek informacyjny RODO Zgodnie z art. 13 ust. 1 i 2 Ogólnego
        Rozporządzenia o Ochronie Danych Osobowych z dnia 27 kwietnia 2016 r.
        (Dz.U.UE.L.2016.119.1 z dnia 2016.05.04); zwanego dalej Rozporządzeniem,
        informujemy:
        <br />
        1.Administratorem danych osobowych jest Danzi Care sp. z o.o. z siedzibą
        W 80-753 Gdańsk, przy ulicy Mariana Seredyńskiego 1/5, zarejestrowana w
        Sądzie Rejonowym XXX, pod numerem KRS: 0000939485, posiadająca NIP:
        5833442026, REGON: XXX;
        <br /> 2. Dane osobowe przetwarzane są w celach: o prowadzenia
        postępowania rekrutacyjnego, o kontaktu z osobą, której dane dotyczą, w
        szczególności w celu przedstawienia warunków współpracy oraz
        przedstawienia ofert zlecenia; o handlowych i marketingowych;
        <br /> 3. Podanie danych osobowych i wyrażenie zgody na ich
        przetwarzanie jest dobrowolne, lecz niezbędne do skorzystania z
        formularza kontaktowego;
        <br /> 4. Z Administratorem danym osobowych można się skontaktować pod
        adresem: 80-753 Gdańsk, Mariana Seredyńskiego 1/5, mail:
        info@danzicare.com, tel. +48 696996663; <br />
        5. Z inspektorem ochrony danych osobowych wyznaczanym w Danzige Care
        spółka z ograniczoną odpowiedzialnością spółka komandytowa można
        skontaktować się pod adresem korespondencyjnym: ul. Mariana
        Seredyńskiego 1/5, 80-753 Gdańsk,
        <br /> 6. Przekazane dane będą przechowywane przez okres nie dłuższy niż
        3 lata, a w przypadku wycofania zgody do momentu złożenia oświadczenia w
        tym przedmiocie;
        <br /> 7. Przekazując dane osobowe ma Pani/Pan prawo dostępu do treści
        swoich danych osobowych, prawo do ich sprostowania, usunięcia, jak
        również do ograniczenia ich przetwarzania, do cofnięcia zgody, do
        przenoszenia danych, do wniesienia sprzeciwu wobec przetwarzania danych
        osobowych;
        <br /> 8. Przysługuje Pani/Panu prawo do wniesienia skargi do organu
        nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych w Warszawie), jeśli
        przetwarzanie danych osobowych narusza przepisy unijnego rozporządzenia
        RODO;
        <br /> 9. Dane osobowe mogą być powierzone do przetwarzania podmiotom
        powiązanym z Administratorem danych lub podmiotom, którym dane
        przekazywane są na zasadzie powierzenia, jeżeli będzie to koniecznie dla
        realizacji procesu rekrutacji (dotyczy: partnerów współpracujących z
        XXXX spółka z ograniczoną odpowiedzialnością spółka komandytowa przy
        obsłudze klientów usług opieki oraz klientów usług opieki);
        <br /> 10. Dane osobowe mogą być przetwarzane w sposób zautomatyzowany,
        ale decyzje w oparciu o podane Panią/Pana dane nie będą podejmowane w
        sposób zautomatyzowany.
      </p>
      <h2 className="rodo__header"> Zgoda marketingowa </h2>
      <p className="rodo__paragraph">
        Wyrażam zgodę na przetwarzanie przez Danzi Care sp. z o.o. z siedzibą w
        80-753 Gdańśk, przy ulicy Mariana Seredyńskiego 1/5 zarejestrowaną w
        Sądzie Rejonowym Katowice-Wschód w Katowicach, pod numerem KRS:
        0000939485, posiadającą NIP: 5833442026, REGON: XXX (Administrator
        danych) moich danych osobowych w postaci imienia, nazwiska, numeru
        telefonu, adresu poczty elektronicznej oraz innych danych przekazanych
        Administratorowi danych w celu przesyłania mi treści marketingowych:
        zgodnie z ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą
        elektroniczną. Obowiązek informacyjny RODO Zgodnie z art. 13 ust. 1 i 2
        Ogólnego Rozporządzenia o Ochronie Danych Osobowych z dnia 27 kwietnia
        2016 r. (Dz.U.UE.L.2016.119.1 z dnia 2016.05.04); zwanego dalej
        Rozporządzeniem, informujemy:
        <br /> 1. Administratorem danych osobowych jest Danzi Care sp. z o.o. z
        siedzibą w 80-744 Gdańsk, przy ulicy Mariana Seredyńskiego 1/5 80-753,
        zarejestrowana w Sądzie Rejonowym XXXXX, pod numerem KRS: 0000939485,
        posiadająca NIP: 5833442026, REGON: XXX;
        <br /> 2. Dane osobowe przetwarzane są w celach: o prowadzenia
        postępowania rekrutacyjnego, o kontaktu z osobą, której dane dotyczą, w
        szczególności w celu przedstawienia warunków współpracy oraz
        przedstawienia ofert zlecenia; o handlowych i marketingowych;
        <br /> 3. Podanie danych osobowych i wyrażenie zgody na ich
        przetwarzanie jest dobrowolne, lecz niezbędne do skorzystania z
        formularza kontaktowego; <br /> 4. Z Administratorem danym osobowych
        można się skontaktować pod adresem: ul. Mariana Seredyńskiego 1/5 80-753
        Gdańsk, mail: info@danzicare.com, tel. +48696996663;
        <br /> 5. Z inspektorem ochrony danych osobowych wyznaczanym w XXX można
        skontaktować się pod adresem korespondencyjnym: ul.Mariana Seredyńskiego
        1/5 80-753 Gdańsk e-mail info@danzicare.com, tel. +48696996663; <br />{" "}
        6. Przekazane dane będą przechowywane przez okres nie dłuższy niż 3
        lata, a w przypadku wycofania zgody do momentu złożenia oświadczenia w
        tym przedmiocie; <br /> 7. Przekazując dane osobowe ma Pani/Pan prawo
        dostępu do treści swoich danych osobowych, prawo do ich sprostowania,
        usunięcia, jak również do ograniczenia ich przetwarzania, do cofnięcia
        zgody, do przenoszenia danych, do wniesienia sprzeciwu wobec
        przetwarzania danych osobowych;
        <br /> 8. Przysługuje Pani/Panu prawo do wniesienia skargi do organu
        nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych w Warszawie), jeśli
        przetwarzanie danych osobowych narusza przepisy unijnego rozporządzenia
        RODO; <br /> 9. Dane osobowe mogą być powierzone do przetwarzania
        podmiotom powiązanym z Administratorem danych lub podmiotom, którym dane
        przekazywane są na zasadzie powierzenia, jeżeli będzie to koniecznie dla
        realizacji procesu rekrutacji (dotyczy: partnerów współpracujących z XXX
        spółka z ograniczoną odpowiedzialnością spółka komandytowa przy obsłudze
        klientów usług opieki oraz klientów usług opieki);
        <br /> 10. Dane osobowe mogą być przetwarzane w sposób zautomatyzowany,
        ale decyzje w oparciu o podane Panią/Pana dane nie będą podejmowane w
        sposób zautomatyzowany.
      </p>
    </div>
  );
};

export default Rodo;
