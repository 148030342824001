import React, { useEffect, useRef, useState } from 'react';
import { setGlobalState } from 'state';
import PrimaryInput from '../PrimaryInput';
import './hintInput.scss';

const HintInput = ({ onChange, name, label, hints, value }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (event.target === ref.current) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div style={{ position: 'relative', height: 'fit-content' }}>
      <PrimaryInput
        onClick={(e) => handleClickOutside(e)}
        ref={ref}
        autocomplete={false}
        onChange={onChange}
        name={name}
        label={label}
        classNameLabel='hint__label'
        className='hint__input'
        value={value}
      />
      <ul
        className='hint__list'
        style={{
          display: isVisible ? 'block' : 'none',
        }}
      >
        {hints &&
          hints.map((h) => {
            return (
              <li
                className='hint__item'
                onClick={() => setGlobalState('codes', h)}
              >
                {h}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default HintInput;
