import React, { useState } from "react";
import { Link } from "react-router-dom";
import routes from "routes/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import Hamburger from "./mobile/Hamburger";
import MobileMenu from "./mobile/MobileMenu";
import logo from "assets/images/logo.jpg";

import "./header.scss";
import LanguageSelector from "components/LanguageSelector";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <div className="header-container">
        <img
          className="header-container__logo"
          src={logo}
          onClick={() => history.push("/")}
        />
        <ul className="header-container__list">
          {routes
            .filter((r) => r.isMenuItem)
            .map((r) => (
              <li key={r.id}>
                <Link className="header-container__list-item" to={r.to}>
                  {t(r.name)}
                </Link>
              </li>
            ))}
          <li className="header-container__list-item header-container__list-item--thick">
            <LanguageSelector />
          </li>
        </ul>
        <Hamburger setIsOpen={setIsOpen} isOpen={isOpen} />
      </div>
      <MobileMenu setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
  );
};

export default Header;
