import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "components/buttons/PrimaryButton";
import Select from "elements/Select";

import { setGlobalState } from "state";
import "./filterPanel.scss";
import { getLocations } from "api/service";
import { useGlobalState } from "state";
import HintInput from "components/inputs/HintInput";
import PrimaryInput from "components/inputs/PrimaryInput";

const FilterPanel = ({ filterData }) => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);
  const [hintCodes, setHintCodes] = useState([]);
  const [codes] = useGlobalState("codes");
  const [codesData] = useGlobalState("codesData");
  const [id] = useGlobalState("id");

  const onChange = (e) => {
    console.log(e.target.name);
    if (e.target.name === "sort") {
      setGlobalState("sort", e.target.value);
    } else if (e.target.name === "location") {
      setGlobalState("location", e.target.value);
    } else if (e.target.name === "codes") {
      setGlobalState("codes", e.target.value);
    } else if (e.target.name === "offer_id") {
      setGlobalState("id", e.target.value);
    }
  };

  useEffect(() => {
    if (codesData.length) {
      setHintCodes(codesData.filter((code) => code?.includes(codes)));
    }
  }, [codes]);

  useEffect(() => {
    getLocations(setLocations);
  }, []);

  return (
    <div className="filter-container">
      <div className="filter-container__element">
        <Select
          name="sort"
          onChange={onChange}
          label={t("sort")}
          options={[
            {
              label: t("start_sort_DESC"),
              value: "_sort=data_rozpoczecia:DESC",
            },
            {
              label: t("start_sort_ASC"),
              value: "_sort=data_rozpoczecia:ASC",
            },
            { label: t("price_sort_DESC"), value: "_sort=stawka:DESC" },
            { label: t("price_sort_ASC"), value: "_sort=stawka:ASC" },
            {
              label: t("offer_for_filter_female"),
              value: "oferta_dla=Opiekunka",
            },
            { label: t("offer_for_filter_male"), value: "oferta_dla=Opiekun" },
            { label: t("Oferta dla (mężczyzna/kobieta)"), value: "oferta_dla=OpiekunLubOpiekunka" },
            { label: t("status_filter"), value: "status=Samotny&status=Samotna" },
            { label: t("care_for_male"), value: "opieka_nad=Senior" },
            { label: t("care_for_female"), value: "opieka_nad=Seniorka" },
            { label: t("care_for_pupil_m"), value: "opieka_nad=Podopieczny" },
            { label: t("care_for_pupil_f"), value: "opieka_nad=Podopieczna" },
            { label: t("care_for_marriage"), value: "opieka_nad=Malzenstwo" },
            { label: t("smoking_filter"), value: "czy_pali=false" },
            { label: t("driveing_licence_filter"), value: "prawo_jazdy=true" },
          ]}
        />
      </div>
      <div className="filter-container__element">
        <HintInput
          onChange={onChange}
          label={t("allLocationsCodes")}
          name="codes"
          hints={hintCodes}
          value={codes}
        />
      </div>
      <div className="filter-container__element">
        <PrimaryInput
        className="filter-primary-input"
          classNameLabel="primary-input-label"
          onChange={onChange}
          label={t("offer_id")}
          name="offer_id"
          value={id}
        />
      </div>
      <div className="filter-container__element">
        <Select
          name="location"
          onChange={onChange}
          label={t("location")}
          options={locations}
        />
      </div>
      <div className="filter-container__element filter-container__element--narrow">
        <PrimaryButton onClick={() => filterData()} color="blue">
          {t("search")}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default FilterPanel;
