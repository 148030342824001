import React from 'react';
import classNames from 'classnames';
import './hamburger.scss';

const Hamburger = ({ setIsOpen, isOpen }) => {
  const handleToggleBurger = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='hamburger-container' onClick={handleToggleBurger}>
      <span
        className={classNames('hamburger-container__block', {
          'hamburger-container__block--rotate-left': isOpen,
        })}
      />
      <span
        className={classNames('hamburger-container__block', {
          'hamburger-container__block--move-right': isOpen,
        })}
      />
      <span
        className={classNames('hamburger-container__block', {
          'hamburger-container__block--rotate-right': isOpen,
        })}
      />
    </div>
  );
};

export default Hamburger;
