import i18n from "i18next";
import PL from "./locals/pl/translations.json";
import BG from "./locals/bg/translations.json";
import CZ from "./locals/cz/translations.json";
import HR from "./locals/hr/translations.json";
import HU from "./locals/hu/translations.json";
import UA from "./locals/ua/translations.json";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  pl: {
    translation: PL,
  },
  bg: {
    translation: BG,
  },
  cz: {
    translation: CZ,
  },
  hr: {
    translation: HR,
  },
  hu: {
    translation: HU,
  },
  ua: {
    translation: UA,
  },
};
i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: "pl", //debug language
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
