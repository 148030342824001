import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import routes from 'routes/routes';
import PageWrapper from 'components/Layout/PageWrapper';

const Root = () => (
  <Router>
    <PageWrapper>
      <Switch>
        {routes.map((r) => (
          <Route
            key={r.id}
            path={r.to}
            component={r.component}
            exact={r.exact}
          />
        ))}
      </Switch>
    </PageWrapper>
  </Router>
);

export default Root;
