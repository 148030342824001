import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import emailJS from "emailjs-com";
import docu from "../../assets/formularz.docx";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
const languageLevel = (level) => {
  switch (level) {
    case "basic":
      return "Die Betreuungsperson kennt Grundbegriffe aus dem Haushalts- und Pflegebereich. Sie kann sich nur auf einfache Art verständigen, wenn der Gesprächspartner langsam und deutlich spricht und bereit ist zu helfen. Die Betreuungsperson versteht nur ganz einfache, kurze und konkrete Fragen, jedoch können Wiederholungen oder Umformulierungen notwendig sein. Sie baut noch keine Sätze und antwortet auf Fragen mit einfachen, einzelnen Wörtern und kurzen Äußerungen.";
    case "communicative":
      return "Betreuungsperson bemüht sich, selbst ein Gespräch aufzubauen. Sie kann mit einfachen Mitteln die direkte Umgebung sowie Umstände im Zusammenhang mit unmittelbaren Bedürfnissen beschreiben. Die Betreuungsperson ist im Stande, einfache Fragen und Sätze zu verstehen, wobei Umformulierungen oder Wiederholungen ab und zu notwendig sein können. Sie bildet schon kurze Sätze, aber ihre Aussagen sind weiterhin grammatikalisch nicht korrekt.";
    case "good":
      return "Die Betreuungsperson kann sich einfach und zusammenhängend über vertraute Themen und persönliche Interessengebiete äußern. Sie kann über Erfahrungen und Ereignisse berichten und kurze Begründungen oder Erklärungen geben. Die Betreuungsperson baut längere, oftmals zusammenhängende Sätze. Weiterhin treten grammatikalische Fehler auf, aber sie ist sich schon den grammatikalischen Regeln bewusst. Sie kann Tempora verwenden, Verben konjugieren und hat einen umfangreicheren Wortschatz. Ein normales Gespräch ist möglich.";
    case "veryGood":
      return "Die Betreuungsperson kann sich in fast jeder sprachlichen Situation zurechtfinden, flexibel reagieren und sich zu verschiedenen Themen äußern. Sie kann sich spontan und fließend verständigen, sodass ein normales Gespräch mit Muttersprachlern ohne größere Anstrengung auf beiden Seiten gut möglich ist. Die Betreuungsperson kann sich zu einem breiten Themenspektrum klar und detailliert ausdrücken und einen Standpunkt zu einer aktuellen Frage erläutern. Sie antwortet mit zusammenhängenden Sätzen und gebraucht vielfältige Wörter. Sie hat keine größeren Schwierigkeiten mit dem Gebrauch der Tempora und mit der Anwendung anderer grammatikalischer Regeln. Manchmal können noch grammatische Fehler auftreten.";
    default:
      return "";
  }
};
const languageLevelTitle = (level) => {
  switch (level) {
    case "basic":
      return "GRUNDKENNTNISSE (Vergleichbar mit der Stufe A1)";
    case "communicative":
      return "KOMMUNIKATIVE DEUTSCHKENNTNISSE (Vergleichbar mit der Stufe A2)";
    case "good":
      return "GUTE DEUTSCHKENNTNISSE (Vergleichbar mit der Stufe B1)";
    case "veryGood":
      return "SEHR GUTE DEUTSCHKENNTNISSE (Vergleichbar mit der Stufe B2)";
    default:
      return "";
  }
};
const languageLevelSkill = (level) => {
  switch (level) {
    case "basic":
      return "GRUNDKENNTNISSE";
    case "communicative":
      return "KOMMUNIKATIVE DEUTSCHKENNTNISSE";
    case "good":
      return "GUTE DEUTSCHKENNTNISSE";
    case "veryGood":
      return "SEHR GUTE DEUTSCHKENNTNISSE";
    default:
      return "";
  }
};

export const generateDocument = (
  {
    driverLicence,
    birthday,
    nationality,
    languageSkills,
    seniorityLabel,
    smokeQuestion,
    fullName,
    photo,
    firstAidCourse,
    deLanguageCourse,
    caregiverCourse,
    reference,
    otherText,
    hobby,
    dateOfStay,
    startDate,
    diseasesLabel,
    contactInfo,
  },
  id,
  reset,
  setIsSending
) => {
  loadFile(docu, function (error, content) {
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });

    const products = [
      firstAidCourse ? "Kurs pierwszej pomocy" : null,
      deLanguageCourse ? "Kurs językowy języka niemieckiego" : null,
      caregiverCourse ? "Kurs opiekunki" : null,
      reference ? "Referencja" : null,
      otherText ? otherText : null,
    ];

    try {
      doc.render({
        birthday: birthday ? new Date(birthday).getFullYear() : "",
        driverLicence: driverLicence === "true" ? "ja" : "nein",
        fullName,
        nationality,
        diseasesLabel,
        hobby,
        languageSkills: languageLevelSkill(languageSkills),
        languageSkillDescription: languageLevel(languageSkills),
        languageSkillTitle: languageLevelTitle(languageSkills),
        seniorityLabel,
        smokeQuestion: smokeQuestion === "true" ? "ja" : "nein",
        additionalSkills: products
          .filter((products) => products !== null)
          .join(", "),
      });
    } catch (error) {
      function replaceErrors(key, value) {
        if (value instanceof Error) {
          return Object.getOwnPropertyNames(value).reduce(function (
            error,
            key
          ) {
            error[key] = value[key];
            return error;
          },
          {});
        }
        return value;
      }
      console.log(JSON.stringify({ error: error }, replaceErrors));

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function (error) {
            return error.properties.explanation;
          })
          .join("\n");
        console.log("errorMessages", errorMessages);
      }
      throw error;
    }
    const documentBase64 = doc.getZip().generate({
      type: "base64",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        if (!file) {
          return;
        }
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    emailJS
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_KEY,
        process.env.REACT_APP_EMAILJS_APLICATION_TEMPLATE,
        {
          file: documentBase64,
          full_name: fullName,
          id,
          dateOfStay,
          startDate,
          contactInfo,
        },
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then((res) => {
        reset();
        setIsSending("sended");
      });
    const handleFileUpload = async () => {
      const file = photo[0];
      if (!file) {
        return;
      }
      const base64 = await convertToBase64(file);
      emailJS
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_KEY,
          process.env.REACT_APP_EMAILJS_PHOTO_TEMPLATE,
          { photo: base64, full_name: fullName, id, contactInfo },
          process.env.REACT_APP_EMAILJS_USER_ID
        )
        .then((res) => console.log(res));
    };
    handleFileUpload();
  });
};
