import React, { useEffect, useState } from "react";
import "./carrer-guide.scss";
import { Document, Page, pdfjs } from "react-pdf";
import PrimaryButton from "components/buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CarrerGuide = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(window.screen.width);
  const { t } = useTranslation();

  useEffect(() => {
    if (window.screen.width < 1025) {
      window.open("/danzige.pdf", "_blank");
      window.location = "/";
    }
    window.addEventListener("resize", () => setPageWidth(window.screen.width));
    return () => {
      window.removeEventListener("resize", () =>
        setPageWidth(window.screen.width)
      );
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const nextPage = () => {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div className="carrer-guide">
      <h1>{t("babysitter_guide")}</h1>
      <div className="carrer-guide__pdf">
        <Document file={"danzige.pdf"} onLoadSuccess={onDocumentLoadSuccess}>
          <Page width={1000} pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <div className="carrer-guide__buttons-box">
          <PrimaryButton
            className="carrer-guide__buttons-box__buttons"
            color="blue"
            onClick={prevPage}
          >
            {t("previous")}
          </PrimaryButton>
          <PrimaryButton
            className="carrer-guide__buttons-box__buttons"
            color="blue"
            onClick={nextPage}
          >
            {t("next")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default CarrerGuide;
