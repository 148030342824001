import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
  page: 0,
  sort: '',
  location: '',
  codes: '',
  id: '',
  codesData: [],
  locationsData: [],
  offers: [],
});

export { useGlobalState, setGlobalState };
