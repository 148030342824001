import React from 'react';
import { useTranslation } from 'react-i18next';
import './checkboxInput.scss';

const CheckboxInput = React.forwardRef(
  ({ onChange, onBlur, name, label, errors }, ref) => {
    const { t } = useTranslation();
    return (
      <div className='checkbox-container'>
        <input
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          type='checkbox'
          className={
            errors
              ? 'checkbox-container__input checkbox-container__input--error'
              : 'checkbox-container__input'
          }
        />
        {errors && (
          <span className='checkbox-container__error'>{t(errors)}</span>
        )}
        <label className='checkbox-container__label'>{t(label)}</label>
      </div>
    );
  }
);

export default CheckboxInput;
