import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getData, getMoreData } from 'api/service';
import { useGlobalState } from 'state';
import OffersItem from './OffersItem';
import FilterPanel from './filterPanel';

import './offers.scss';

const Offers = () => {
  const [isMore, setIsMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useGlobalState('page');
  const [location] = useGlobalState('location');
  const [sort] = useGlobalState('sort');
  const [codes] = useGlobalState('codes');
  const [id] = useGlobalState('id');
  const [offers, setOffers] = useGlobalState('offers');

  const filterData = () => {
    setPage(0);
    setIsMore(true);
    getData(sort, 0, { location, codes, id }, setOffers, setIsMore);
  };

  useEffect(() => {
    if (page === 0) {
      getData(sort, page, { location, codes, id }, setOffers, setIsMore);
    } else {
      getMoreData(
        sort,
        page,
        { location, codes, id },
        setOffers,
        setIsMore,
        setIsLoading
      );
    }
  }, [page]);

  const getNextPage = () => {
    if (isLoading) return;
    setPage((prevPage) => prevPage + 5);
    setIsLoading(true);
  };

  return (
    <div className='offers-page'>
      <div className='offers-page__filter-box--hidden'>
        <FilterPanel filterData={filterData} />
      </div>
      <InfiniteScroll
        dataLength={offers.length}
        hasMore={isMore}
        next={() => getNextPage()}
        className='offers-page__box'
      >
        <div className='offers-page__filter-box'>
          <FilterPanel filterData={filterData} />
        </div>
        {offers.length ? (
          offers
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id)
            )
            .map((offer) => (
              <li style={{ width: '100%', listStyle: 'none' }}>
                <OffersItem {...offer} />
              </li>
            ))
        ) : (
          <div
            style={{
              width: '100%',
              minWidth: '1000px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            brak ofert
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default Offers;
