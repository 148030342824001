import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import LanguageIcon from 'assets/language.svg';
import ExpandIcon from 'assets/expand.svg';

import './languageSelector.scss';

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <div className='language-container' onClick={() => handleToggleOpen()}>
      <div className='language-container__selector'>
        <ReactSVG
          src={LanguageIcon}
          className='language-container__icon language-container__icon--margin-right'
        />
        <p className='mobile-list__item__link'>
          {localStorage.getItem('i18nextLng').toUpperCase()}
        </p>
        <ReactSVG
          className={classNames('language-container__icon', {
            'language-container__icon--rotated': isOpen,
          })}
          src={ExpandIcon}
        />
      </div>
      {isOpen && (
        <ul
          className={classNames('language-container__list', {
            'language-container__list--open': isOpen,
          })}
        >
          <li
            className='mobile-list__item__link mobile-item'
            onClick={() => handleClick('pl')}
          >
            PL
          </li>
          <li
            className='mobile-list__item__link mobile-item'
            onClick={() => handleClick('bg')}
          >
            BG
          </li>
          <li
            className='mobile-list__item__link mobile-item'
            onClick={() => handleClick('cz')}
          >
            CZ
          </li>
          <li
            className='mobile-list__item__link mobile-item'
            onClick={() => handleClick('hr')}
          >
            HR
          </li>
          <li
            className='mobile-list__item__link mobile-item'
            onClick={() => handleClick('hu')}
          >
            HU
          </li>
          <li
            className='mobile-list__item__link mobile-item'
            onClick={() => handleClick('ua')}
          >
            UA
          </li>
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
