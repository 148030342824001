import ContactForm from "components/forms/ContactForm";
import React from "react";
import "./contact.scss";
import pic from "./images/pic1.jpg";
import laptop from "./images/laptop.jpg";
import PrimaryButton from "components/buttons/PrimaryButton";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-page">
      <h1 className="contact-page__header">{t("contact")}</h1>
      <div className="contact-page__upper">
        <div className="contact-page__upper__contact-data">
          <div className="contact-page__upper__contact-data__wrapper">
            <div className="contact-page__upper__contact-data__row">
              <a className="contact-page__upper__contact-data__header">
                {t("haveAnyQ")}
                <br /> {t("callToOurOffice")}! <br />
              </a>
              <a>Danzi Care sp. z o.o. </a>{" "}
              <a>Kapitał zakładowy 5 000,00 PLN</a> <br />{" "}
              <a className="contact-page__upper__contact-data__header">KRS:</a>
              <a>0000939485</a>
            </div>
            <div className="contact-page__upper__contact-data__row">
              <a className="contact-page__upper__contact-data__header">
                {t("company_address")}:
              </a>
              <a>{t("street")}.Mariana Seredyńskiego 1/5 80-753 Gdańsk</a>
            </div>
            <div className="contact-page__upper__contact-data__row">
              <a className="contact-page__upper__contact-data__header">
                {t("recruitment_office")}:
              </a>
              <a>{t("street")}.Łąkowa 5/2 80-744 Gdańsk</a>
            </div>
            <div className="contact-page__upper__contact-data__row">
              <a className="contact-page__upper__contact-data__header">NIP:</a>
              <a> 5833442026</a>
            </div>
            <div className="contact-page__upper__contact-data__row">
              <a className="contact-page__upper__contact-data__header">
                {t("phoneNumber")}:
              </a>
              <a>
                0048 696996663, 0048 692107099, 0048 664532648, 0048 692547039
              </a>
            </div>
            <div className="contact-page__upper__contact-data__row">
              <a className="contact-page__upper__contact-data__header">
                {t("phoneNumber")} {t("recruitment_office_small")}:
              </a>
              <a>
                0048 696996663, 0048 692107099, 0048 664532648, 0048 664450845,
                0048 692547039
              </a>
            </div>
          </div>

          <img
            src={laptop}
            className="contact-page__upper__contact-data__laptop"
          />
        </div>
        <div className="contact-page__upper__form">
          <img src={pic} className="contact-page__upper__form__picture" />
          <p className="contact-page__upper__form__p">
            {t("inviteToCooperate")}! <br /> {t("contactPageFirstDescription")}
          </p>
          <div className="contact-page__upper__form__btn">
            <PrimaryButton color="blue">{t("applay")}</PrimaryButton>
          </div>
        </div>
      </div>
      <div className="contact-page__bottom">
        <div className="contact-page__bottom__text">
          <h4>{t("emergencyPhone")}</h4>
          <p>
            {t("contactPageSecondDescription")}
            <br />
            {t("phoneNumber")}: 0048 664450845
          </p>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
