import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import PrimaryButton from "components/buttons/PrimaryButton";
import PATH_URL from "routes/pathURL";

import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import "./slider.scss";

import image1 from "assets/images/c1.jpg";
import image2 from "assets/images/c2.jpg";
import image3 from "assets/images/c3.jpg";
import image4 from "assets/images/c4.jpg";

const images = [
  {
    src: image1,
    tag: (
      <a className="freepik-url" href="https://pl.freepik.com/zdjecia/ludzie">
        Ludzie zdjęcie utworzone przez pressfoto - pl.freepik.com
      </a>
    ),
  },
  {
    src: image2,
    tag: (
      <a className="freepik-url" href="https://pl.freepik.com/zdjecia/ludzie">
        Ludzie zdjęcie utworzone przez artursafronovvvv - pl.freepik.com
      </a>
    ),
  },
  {
    src: image3,
    tag: (
      <a className="freepik-url" href="https://pl.freepik.com/zdjecia/ludzie">
        Ludzie zdjęcie utworzone przez pressfoto - pl.freepik.com
      </a>
    ),
  },
  {
    src: image4,
    tag: (
      <a className="freepik-url" href="https://pl.freepik.com/zdjecia/medyczny">
        Medyczny zdjęcie utworzone przez freepik - pl.freepik.com
      </a>
    ),
  },
];

const Slider = ({ offers, type }) => {
  const isImage = type === "img";

  const [items, setItems] = useState(isImage ? images : []);
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!isImage) {
      setItems(offers);
    }
  }, [offers]);

  useEffect(() => {
    const lastIndex = items.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, items]);

  useEffect(() => {
    let slider = setInterval(
      () => {
        setIndex(index + 1);
      },
      isImage ? 4000 : 5000
    );
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <div className="wrapper-container">
      <div className="section-center">
        {items.length &&
          items.map((item, itemIndex) => {
            let position = "nextSlide";
            if (itemIndex === index) {
              position = "activeSlide";
            }
            if (
              itemIndex === index - 1 ||
              (index === 0 && itemIndex === items.length - 1)
            ) {
              position = "lastSlide";
            }

            return isImage ? (
              <article className={`${position} zoom-in image-container`}>
                <img
                  src={item.src}
                  alt="s"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <div style={{ zIndex: 1000, position: "absolute", bottom: 0 }}>
                  {item.tag}
                </div>
              </article>
            ) : (
              <article className={`${position} image-container`}>
                <div className="daily-container">
                  <h4 className="daily-container__title">
                    {item.tytul.length > 90
                      ? item.tytul.slice(0, 90) + "..."
                      : item.tytul}
                  </h4>
                  <div className="daily-container__image-box">
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "200px",
                        maxWidth: "200px",
                        objectFit: "contain",
                      }}
                      src={`${process.env.REACT_APP_STRAPI_CLEAN}${item?.zdjecie?.url}`}
                    />
                  </div>
                  <div className="daily-container__content-box">
                    <ul className="offer-container__details offer-container__details">
                      <li className="daily-container__details-item">
                        {t("location")}:{" "}
                        <span className="offer-container__details-value">
                          {item.location?.miasto} {item.location?.kod}
                        </span>
                      </li>
                      <li className="daily-container__details-item">
                        {t("date")}:{" "}
                        <span className="offer-container__details-value">
                          od{" "}
                          {new Date(item.data_rozpoczecia).getTime() <
                          new Date()
                            ? "zaraz"
                            : item.data_rozpoczecia}
                          {item.data_zakonczenia &&
                            item.data_rozpoczecia !== item.data_zakonczenia &&
                            " do " + item.data_zakonczenia}
                        </span>
                      </li>
                      <li className="daily-container__details-item">
                        {t("salary")}:{" "}
                        <span className="offer-container__details-value">
                          {item.stawka}€ + PREMIA ŚWIĄTECZNA
                        </span>
                      </li>
                      <li className="daily-container__details-item">
                        {t("candidateType")}:{" "}
                        <span className="offer-container__details-value">
                          {item.oferta_dla}
                        </span>
                      </li>
                      <li className="daily-container__details-item">
                        {t("driverLicenceOffer")}:{" "}
                        <span className="offer-container__details-value">
                          {item.prawo_jazdy ? t("yes") : t("no")}
                        </span>
                      </li>
                      <li className="daily-container__details-item">
                        {t("smokeingOffer")}:{" "}
                        <span className="offer-container__details-value">
                          {item.czy_pali ? t("allowed") : t("smoking_filter")}
                        </span>
                      </li>
                      <li className="daily-container__details-item">
                        {t("language")}:{" "}
                        <span className="daily-container__details-value">
                          {item.jezyk?.length > 30
                            ? item.jezyk.slice(0, 40) + "..."
                            : item.jezyk}
                        </span>
                      </li>
                      <li className="daily-container__details-item">
                        ID:
                        <span className="daily-container__details-value">
                          {item.offer_id}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="daily-container__button-box">
                    <PrimaryButton
                      onClick={() =>
                        history.push(PATH_URL.offers + "/" + item.id, {
                          props: { ...item },
                        })
                      }
                      color="blue"
                    >
                      {t("readMore")}
                    </PrimaryButton>
                  </div>
                </div>
              </article>
            );
          })}
        {!isImage && (
          <>
            <button className="prev" onClick={() => setIndex(index - 1)}>
              <FiChevronLeft className="icon" />
            </button>
            <button className="next" onClick={() => setIndex(index + 1)}>
              <FiChevronRight className="icon" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Slider;
