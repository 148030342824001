import React from "react";
import "./about.scss";
import logo from "assets/images/logo2.jpg";
import { useTranslation } from "react-i18next";
import pic1 from "./images/pic1.jpg";
import pic4 from "./images/pic4.jpg";
import pic5 from "./images/pic5.jpg";
const About = () => {
  const { t } = useTranslation();
  return (
    <div className="wrapper-about">
      <div className="about">
        <div className="about__pictures">
          <div className="about__pictures__wrapper">
            <div className="about__pictures__pic-container">
              <img className="about__pictures__pic-container__pic" src={pic4} />
            </div>
            <h2>Karolina</h2>
          </div>
          <div className="about__pictures__wrapper">
            <div className="about__pictures__pic-container">
              <img className="about__pictures__pic-container__pic" src={pic1} />
            </div>
            <h2>Agnieszka</h2>
          </div>
        </div>
        <div className="about__center">
          <h2 className="about__header">{t("meetOurTeam")}!</h2>
          <p className="about__paragraph">{t("aboutUsFirstDescription")}</p>
          <p className="about__paragraph">{t("aboutUsSecondDescription")}</p>
          <p className="about__paragraph">{t("aboutUsThirdDescription")} </p>
          <img src={logo} className="about__logo" />
          <h3 className="about__h3">{t("inviteToCooperate")}!</h3>
        </div>
        <div className="about__pictures">
          <div className="about__pictures__wrapper">
            <div className="about__pictures__pic-container"></div>
            <h2></h2>
          </div>
          <div className="about__pictures__wrapper">
            <div className="about__pictures__pic-container">
              <img className="about__pictures__pic-container__pic" src={pic5} />
            </div>
            <h2>Julia</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
